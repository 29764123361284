import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import logo from '../../images/logo.png'
import { databaseLocation } from '../../dummyData'
import axios from 'axios'

const Topbar = ({ loggedIn, user, setLoggedIn, setUser, setShowLoginPopup }) => {
    const [inCartAmount, setInCartAmount] = useState(0)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropdownRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        let isMounted = true
        if (loggedIn) {
            axios.get(`${databaseLocation}/getInCartAmount/${user.userId}`).then((response) => {
                if (isMounted) {
                    setInCartAmount(response.data[0].amount)
                }
            })
        }

        // Close dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            isMounted = false
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [loggedIn])

    const handleLogout = () => {
        setLoggedIn(false)
        setUser({})
        navigate('/login')
    }

    return (
        <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-40">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 pl-16">
                <div className="flex justify-between items-center">
                    {/* Logo */}
                    <div className="flex-shrink-0 md:ml-10 sm:ml-10">
                        <Link to="/shop" className="block">
                            <img 
                                src={logo} 
                                alt="MD-Teile Logo" 
                                className="h-16 w-auto sm:h-20 object-contain"
                            />
                        </Link>
                    </div>

                    {/* Right Navigation */}
                    <div className="flex items-center space-x-6">
                        {loggedIn ? (
                            <div className="flex items-center space-x-6">
                                {/* Shopping Cart */}
                                <Link 
                                    to="/cart" 
                                    className="relative group"
                                >
                                    <div className="p-2 rounded-full border border-gray-200 hover:border-blue-500 transition-colors duration-200">
                                        <ShoppingCartIcon 
                                            className="h-6 w-6 text-gray-700 group-hover:text-blue-500 transition-colors duration-200"
                                        />
                                    </div>
                                    {inCartAmount > 0 && (
                                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-medium px-2 py-1 rounded-full min-w-[20px] h-5 flex items-center justify-center">
                                            {inCartAmount}
                                        </span>
                                    )}
                                </Link>

                                {/* User Profile with Dropdown */}
                                <div className="relative" ref={dropdownRef}>
                                    <button 
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                        className="focus:outline-none"
                                    >
                                        <img
                                            src={user.avatar}
                                            alt="Profil"
                                            className="h-10 w-10 rounded-full object-cover ring-2 ring-gray-200 hover:ring-blue-500 transition-colors duration-200"
                                        />
                                    </button>

                                    {/* Dropdown Menu */}
                                    {isDropdownOpen && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                                            <Link 
                                                to="/profile"
                                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                                                onClick={() => setIsDropdownOpen(false)}
                                            >
                                                <PersonIcon className="h-5 w-5 mr-2 text-gray-500" />
                                                Profil
                                            </Link>
                                            <button
                                                onClick={() => {
                                                    setIsDropdownOpen(false)
                                                    handleLogout()
                                                }}
                                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                                            >
                                                <LogoutIcon className="h-5 w-5 mr-2 text-gray-500" />
                                                Abmelden
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <button 
                                onClick={() => setShowLoginPopup(true)}
                                className="p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                            >
                                <AdminPanelSettingsIcon className="h-6 w-6 text-gray-700 hover:text-blue-500 transition-colors duration-200" />
                            </button>
                        )}
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Topbar
