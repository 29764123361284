import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { databaseLocation } from '../../../dummyData';
import { TruckIcon } from '@heroicons/react/24/outline';

export default function VehicleList({ article }) {
    const [vehicles, setVehicles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                setIsLoading(true);
                const response = await Axios.get(`${databaseLocation}/api/vehicleList/${article.articleId}/withKType`);
                if (response.data && !response.data.includes("not logged in")) {
                    setVehicles(response.data);
                }
                setError(null);
            } catch (err) {
                setError('Fehler beim Laden der Fahrzeuge');
            } finally {
                setIsLoading(false);
            }
        };

        fetchVehicles();
    }, [article.articleId]);

    if (isLoading) {
        return (
            <div className="px-4 py-5 sm:p-6 flex justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="px-4 py-5 sm:p-6">
                <div className="bg-red-50 border-l-4 border-red-500 p-4">
                    <div className="flex">
                        <div className="ml-3">
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!vehicles || vehicles.length === 0) {
        return null;
    }

    return (
        <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center mb-6">
                <TruckIcon className="h-6 w-6 text-blue-500 mr-2" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Passende Fahrzeuge
                </h3>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Marke
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Modell
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Typ
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Jahr
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Motor
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                HSN/TSN
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {vehicles.map((vehicle, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {vehicle.brand}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {vehicle.model}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {vehicle.vType}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {vehicle.year}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {vehicle.engine}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {vehicle.hsnTsn.replaceAll("<>", " ")}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
