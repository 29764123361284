import { Helmet } from 'react-helmet';
import { MapPinIcon, PhoneIcon, EnvelopeIcon, ClockIcon } from '@heroicons/react/24/outline';

export default function Contact() {
    return (
        <>
            <Helmet>
                <title>Kontakt | Maschinenbau Dünder - Ihr Spezialist für Anlasser & Lichtmaschinen</title>
                <meta name="description" content="Kontaktieren Sie Maschinenbau Dünder in Berlin Spandau - Ihr Experte für Anlasser und Lichtmaschinen. Professionelle Beratung und Service für alle Fahrzeugtypen." />
                <meta name="keywords" content="Maschinenbau Dünder, Kontakt, Berlin Spandau, Anlasser, Lichtmaschinen, Fahrzeugteile, Beratung" />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    {/* Hero Section */}
                    <div className="text-center mb-16">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Kontaktieren Sie uns
                        </h1>
                        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                            Wir sind Ihr zuverlässiger Partner für Anlasser & Lichtmaschinen in Berlin Spandau.
                            Sprechen Sie uns an - wir beraten Sie gerne!
                        </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        {/* Left Column - Company Info */}
                        <div className="space-y-12">
                            {/* About Us Section */}
                            <section className="bg-white rounded-lg shadow-lg p-8">
                                <h2 className="text-2xl font-semibold text-gray-900 mb-6">Über uns</h2>
                                <p className="text-gray-600 leading-relaxed">
                                    Maschinenbau Dünder ist ein mittelständiges Unternehmen mit Sitz in Berlin Spandau.
                                    Wir sind spezialisiert auf Anlasser & Lichtmaschinen für jegliche Fahrzeugtypen.
                                    In unserem Sortiment führen wir sowohl hochwertig generalüberholte als auch neue Aggregate.
                                </p>
                            </section>

                            {/* Contact Info Cards */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Opening Hours */}
                                <div className="bg-white rounded-lg shadow-lg p-6">
                                    <div className="flex items-center mb-4">
                                        <ClockIcon className="h-6 w-6 text-blue-600" />
                                        <h3 className="text-lg font-semibold text-gray-900 ml-2">Öffnungszeiten</h3>
                                    </div>
                                    <p className="text-gray-600">
                                        Montag - Freitag<br />
                                        8:00 - 17:00 Uhr
                                    </p>
                                </div>

                                {/* Phone & Email */}
                                <div className="bg-white rounded-lg shadow-lg p-6">
                                    <div className="space-y-4">
                                        <div>
                                            <div className="flex items-center mb-2">
                                                <PhoneIcon className="h-6 w-6 text-blue-600" />
                                                <h3 className="text-lg font-semibold text-gray-900 ml-2">Telefon</h3>
                                            </div>
                                            <p className="text-gray-600">030 308 373 62</p>
                                        </div>
                                        <div>
                                            <div className="flex items-center mb-2">
                                                <EnvelopeIcon className="h-6 w-6 text-blue-600" />
                                                <h3 className="text-lg font-semibold text-gray-900 ml-2">E-Mail</h3>
                                            </div>
                                            <a href="mailto:sales@md-teile.de" 
                               className="text-blue-600 hover:text-blue-800 break-all">
                                                sales@md-teile.de
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Address & Map */}
                        <div className="space-y-8">
                            {/* Address Card */}
                            <div className="bg-white rounded-lg shadow-lg p-8">
                                <div className="flex items-center mb-6">
                                    <MapPinIcon className="h-6 w-6 text-blue-600" />
                                    <h2 className="text-2xl font-semibold text-gray-900 ml-2">Adresse</h2>
                                </div>
                                <div className="space-y-2 text-gray-600">
                                    <p className="font-semibold">Maschinenbau Dünder</p>
                                    <p>Brunsbütteler Damm 454</p>
                                    <p>13591 Berlin</p>
                                    <p>Deutschland</p>
                                </div>
                            </div>

                            {/* Embedded Map */}
                            <div className="bg-white rounded-lg shadow-lg p-4 h-96">
                                <iframe
                                    title="Standort Maschinenbau Dünder"
                                    className="w-full h-full rounded-lg"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.4029549041395!2d13.158899!3d52.532899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8572374755555%3A0x69c4f0c5d7a0ebb8!2sBrunsb%C3%BCtteler%20Damm%20454%2C%2013591%20Berlin!5e0!3m2!1sde!2sde!4v1234567890"
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Section - Web Development */}
                    <div className="mt-16 bg-gradient-to-r from-blue-600 to-blue-800 rounded-lg shadow-lg p-8 text-white">
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-2xl font-semibold mb-4">
                                Brauchen Sie einen Webshop?
                            </h2>
                            <p className="text-lg mb-6">
                                Wir helfen Ihnen dabei, Ihre Online-Präsenz aufzubauen und zu optimieren.
                            </p>
                            <div className="inline-flex items-center space-x-2">
                                <span>Kontaktieren Sie uns unter:</span>
                                <a href="mailto:sales@kernlicht.de" 
                                   className="font-semibold hover:text-blue-100 transition-colors">
                                    sales@kernlicht.de
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}