import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useLocation } from 'react-router-dom';
import Login from '../../pages/login/Login';
import { databaseLocation } from '../../dummyData';

export default function LoginRegister({ setShowLoginPopup, setUser, loggedIn, setLoggedIn }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClose = (e) => {
        // Prevent event from bubbling up to parent elements
        e.stopPropagation();
        console.log('Close button clicked');
        setShowLoginPopup(false);
        
        // If we're on the cart page, redirect to home
        if (location.pathname === '/cart') {
            navigate('/shop');
        }
    };

    const handleOverlayClick = (e) => {
        // Only close if clicking the overlay itself, not its children
        if (e.target === e.currentTarget) {
            console.log('Overlay clicked');
            setShowLoginPopup(false);
            
            // If we're on the cart page, redirect to home
            if (location.pathname === '/cart') {
                navigate('/shop');
            }
        }
    };

    return (
        // Remove pointer events from the outer container to prevent overlay issues
        <div className="fixed inset-0 z-50 overflow-y-auto pointer-events-none" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                {/* Background overlay */}
                <div 
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer pointer-events-auto"
                    onClick={handleOverlayClick}
                    aria-hidden="true"
                />

                {/* Modal panel */}
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 relative pointer-events-auto">
                    {/* Close button */}
                    <div className="absolute top-0 right-0 pt-4 pr-4 z-10">
                        <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 p-1"
                            onClick={handleClose}
                        >
                            <span className="sr-only">Schließen</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    {/* Content */}
                    <div className="mt-3">
                        <Login 
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                            setUser={setUser}
                            desiredPath=""
                            databaseLocation={databaseLocation}
                            onLoginSuccess={() => setShowLoginPopup(false)}
                            isPopup={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}