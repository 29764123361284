import { Helmet } from 'react-helmet';
import { BuildingOfficeIcon, PhoneIcon, EnvelopeIcon, ScaleIcon } from '@heroicons/react/24/outline';

export default function Imprint() {
    return (
        <>
            <Helmet>
                <title>Impressum | Maschinenbau Dünder - Kontakt & Rechtliche Informationen</title>
                <meta name="description" content="Impressum von Maschinenbau Dünder - Finden Sie hier unsere vollständigen Kontaktdaten und rechtlichen Informationen gemäß § 5 TMG." />
                <meta name="keywords" content="Impressum, Maschinenbau Dünder, Kontakt, Rechtliche Informationen, TMG, Berlin" />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    {/* Header Section */}
                    <div className="text-center mb-12">
                        <div className="flex justify-center mb-4">
                            <BuildingOfficeIcon className="h-16 w-16 text-blue-600" />
                        </div>
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Maschinenbau Dünder
                        </h1>
                        <p className="text-lg text-gray-600">
                            Angaben gemäß § 5 TMG
                        </p>
                    </div>

                    <div className="space-y-8">
                        {/* Company Information */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
                                <BuildingOfficeIcon className="h-6 w-6 text-blue-600 mr-2" />
                                Vertreten durch
                            </h2>
                            <div className="prose prose-lg max-w-none text-gray-600">
                                <div className="space-y-2">
                                    <p className="font-medium">Abdullah Dünder</p>
                                    <p>Brunsbütteler Damm 454</p>
                                    <p>13591 Berlin</p>
                                </div>
                            </div>
                        </section>

                        {/* Contact Information */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                                Kontakt
                            </h2>
                            <div className="grid md:grid-cols-2 gap-6">
                                <div className="flex items-start">
                                    <PhoneIcon className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-1">Telefon</h3>
                                        <p className="text-gray-600">030 30837362</p>
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <EnvelopeIcon className="h-6 w-6 text-blue-600 mt-1 mr-3" />
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-1">E-Mail</h3>
                                        <a href="mailto:info@md-teile.de" 
                                           className="text-blue-600 hover:text-blue-800">
                                            info@md-teile.de
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Tax Information */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6 flex items-center">
                                <ScaleIcon className="h-6 w-6 text-blue-600 mr-2" />
                                Umsatzsteuer-ID
                            </h2>
                            <div className="prose prose-lg max-w-none text-gray-600">
                                <p className="mb-2">
                                    Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
                                </p>
                                <p className="font-medium text-gray-900">
                                    DE252414395
                                </p>
                            </div>
                        </section>

                        {/* Online Dispute Resolution */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                                Informationen zur Online-Streitbeilegung
                            </h2>
                            <div className="prose prose-lg max-w-none text-gray-600 space-y-4">
                                <p>
                                    Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten 
                                    (sog. „OS-Plattform") geschaffen.
                                </p>
                                <p>
                                    Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von 
                                    Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen.
                                </p>
                                <div className="bg-blue-50 rounded-lg p-6">
                                    <p className="text-blue-900">
                                        Sie können die OS-Plattform unter dem folgenden Link erreichen:{' '}
                                        <a href="http://ec.europa.eu/consumers/odr" 
                                           target="_blank" 
                                           rel="noopener noreferrer"
                                           className="text-blue-600 hover:text-blue-800">
                                            http://ec.europa.eu/consumers/odr
                                        </a>
                                    </p>
                                    <p className="text-sm text-blue-700 mt-2">
                                        Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}
