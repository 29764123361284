import { Helmet } from 'react-helmet';
import Axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { databaseLocation } from '../../dummyData';
import PayPal from '../../components/PayPal';
import VehicleList from './components/VehicleList';
import ProductDetails from './components/ProductDetails';
import OENumbers from './components/OENumbers';
import { ShoppingCartIcon, TruckIcon, ShieldCheckIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import NoPic from "../../nopic.png";

export default function Product({ user, setUser }) {
    const location = useLocation();
    const articleId = location.pathname.split("/")[2];
    const [article, setArticle] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchArticle = async () => {
            try {
                setIsLoading(true);
                const response = await Axios.get(`${databaseLocation}/api/get/${articleId}`);
                if (isMounted) {
                    if (!response.data.includes("not logged in")) {
                        setArticle(response.data[0]);
                        setError(null);
                    }
                }
            } catch (err) {
                if (isMounted) {
                    setError('Fehler beim Laden des Artikels. Bitte versuchen Sie es später erneut.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchArticle();
        return () => {
            isMounted = false;
        };
    }, [articleId]);

    const addToCart = async () => {
        console.log("user:", user, "add smth to cart");
        if (user.userId) {
            try {
                const response = await Axios.post(`${databaseLocation}/addToCart`, {
                    customerId: user.userId,
                    articleId: article.articleId,
                    amount: 1,
                    product: article
                });
                if (response.data.success) {
                    const updatedCartAmount = user.cartAmount ? user.cartAmount + 1 : 1;
                    setUser({ ...user, cartAmount: updatedCartAmount });
                } else {
                    throw new Error('Failed to add product to cart');
                }
            } catch (error) {
                console.error('Error adding to cart:', error);
            }
        } else {
            console.log('User not logged in');
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="bg-red-50 border-l-4 border-red-500 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ArrowPathIcon className="h-5 w-5 text-red-400" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!article) return null;

    return (
        <>
            <Helmet>
                <title>{article.articleType} {article.articleNumber} | Maschinenbau Dünder</title>
                <meta 
                    name="description" 
                    content={`${article.articleType} ${article.manufacturer} ${article.articleNumber}. Qualitäts-Autoteile von Maschinenbau Dünder.`} 
                />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    {/* Product Header */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="p-6 sm:p-8">
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                                {/* Image Section */}
                                <div className="space-y-4">
                                    <h1 className="text-2xl font-bold text-gray-900">{article.articleNumber}</h1>
                                    <div className="aspect-square rounded-lg overflow-hidden bg-gray-100">
                                        <img 
                                            src={article.URL || NoPic} 
                                            alt={article.articleType} 
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                </div>

                                {/* Details Section */}
                                <div className="space-y-6">
                                    <div className="space-y-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="text-sm text-gray-500">Artikel</div>
                                            <div className="text-sm font-medium text-gray-900">{article.articleType.includes("Alternator") ? "Lichtmaschine" : article.articleType}</div>
                                            
                                            <div className="text-sm text-gray-500">Zustand</div>
                                            <div className="text-sm font-medium text-gray-900">{article.state.includes("Reman") ? "Generalüberholt" : article.state}</div>
                                            
                                            <div className="text-sm text-gray-500">Hersteller</div>
                                            <div className="text-sm font-medium text-gray-900">{article.manufacturer}</div>
                                        </div>

                                        <div className="pt-4">
                                            <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                                                ${article.quantity > 0 
                                                    ? 'bg-green-100 text-green-800' 
                                                    : 'bg-red-100 text-red-800'}`}
                                            >
                                                {article.quantity > 0 ? 'Verfügbar' : 'Ausverkauft'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Purchase Section */}
                                <div className="lg:pl-8">
                                    <div className="bg-gray-50 rounded-lg p-6 space-y-6">
                                        <div className="flex justify-between items-center">
                                            <span className="text-2xl font-bold text-gray-900">{article.ebayPlus}€</span>
                                            <span className="text-sm text-gray-500">inkl. MwSt</span>
                                        </div>

                                        <div className="space-y-2">
                                            <div className="flex items-center text-sm text-gray-500">
                                                <TruckIcon className="h-5 w-5 mr-2 text-green-500" />
                                                <span>Schnelle Lieferung (1-2 Werktage)</span>
                                            </div>
                                            <div className="flex items-center text-sm text-gray-500">
                                                <ShieldCheckIcon className="h-5 w-5 mr-2 text-blue-500" />
                                                <span>Sichere Bezahlung</span>
                                            </div>
                                        </div>

                                        <div className="space-y-3">
                                            <PayPal article={article} />
                                            <Link 
                                                to="/cart" 
                                                className="w-full"
                                            >
                                                <button
                                                    onClick={addToCart}
                                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                >
                                                    <ShoppingCartIcon className="h-5 w-5 mr-2" />
                                                    Bestellen
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Product Body */}
                    <div className="mt-8 space-y-8">
                        {article.articleType === "Alternator" && (
                            <div className="bg-red-50 border border-red-200 rounded-lg p-6">
                                <div className="text-red-700 text-center">
                                    <p className="font-medium">Wichtiger Hinweis zum Pfand</p>
                                    <p className="mt-2">
                                        Wir benötigen Ihre alte Lichtmaschine innerhalb von 14 Tagen zurück, 
                                        andernfalls kann Ihnen nach Ablauf der 14 Tage 50 EUR Pfand berechnet werden!
                                    </p>
                                    <p className="mt-2 text-sm">
                                        In der Regel liegt ein Paketschein für den Rückversand an uns mit bei, 
                                        dieser ist allerdings nur für Käufer innerhalb Deutschlands nutzbar.
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <ProductDetails article={article} />
                        </div>

                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <VehicleList article={article} />
                        </div>

                        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                            <OENumbers article={article} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
