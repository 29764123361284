import { useState } from 'react';
import SidebarMenu from "./SidebarMenu";
import MenuIcon from '@mui/icons-material/Menu';

export default function Sidebar({loggedIn}) {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
    }

    return (
        <>
            <button 
                onClick={showSidebar}
                className="fixed top-6 left-4 z-50 p-2 rounded-full bg-blue-100 hover:bg-blue-200 transition-colors duration-200 cursor-pointer"
            >
                <MenuIcon className="h-6 w-6 text-blue-700" />
            </button>
            
            <div className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
                sidebar ? 'translate-x-0' : '-translate-x-full'
            }`}>
                <SidebarMenu loggedIn={loggedIn} setSidebar={setSidebar}/>
            </div>
            
            {/* Overlay */}
            {sidebar && (
                <div 
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300"
                    onClick={() => setSidebar(false)}
                />
            )}
        </>
    )
}
