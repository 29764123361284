import { Helmet } from 'react-helmet';
import { ShieldCheckIcon, LockClosedIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

export default function DataProtection() {
    return (
        <>
            <Helmet>
                <title>Datenschutzerklärung | LaMaLagerWelt - Sicher & Transparent</title>
                <meta name="description" content="Datenschutzerklärung von LaMaLagerWelt - Erfahren Sie, wie wir Ihre persönlichen Daten schützen und verarbeiten. Transparente Informationen zu Datenschutz und Datensicherheit." />
                <meta name="keywords" content="Datenschutz, DSGVO, Datenschutzerklärung, Datensicherheit, LaMaLagerWelt, Datenschutzrichtlinien" />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    {/* Header Section */}
                    <div className="text-center mb-12">
                        <div className="flex justify-center mb-4">
                            <ShieldCheckIcon className="h-16 w-16 text-blue-600" />
                        </div>
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Datenschutzerklärung
                        </h1>
                        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                            Wir legen größten Wert auf den Schutz Ihrer persönlichen Daten. 
                            Hier erfahren Sie, wie wir Ihre Daten verarbeiten und schützen.
                        </p>
                    </div>

                    {/* Main Content */}
                    <div className="space-y-8">
                        {/* Order Process Section */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <div className="flex items-center mb-6">
                                <DocumentTextIcon className="h-8 w-8 text-blue-600 mr-3" />
                                <h2 className="text-2xl font-semibold text-gray-900">
                                    Bestellvorgang
                                </h2>
                            </div>
                            
                            <div className="prose prose-lg max-w-none text-gray-600">
                                <p className="mb-6">
                                    Im Rahmen der Bestellabwicklung werden folgende Daten von unseren Kunden gespeichert:
                                </p>

                                <div className="bg-gray-50 rounded-lg p-6 mb-6">
                                    <ul className="list-none space-y-3">
                                        <li className="flex items-center">
                                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                                            Name, Vorname
                                        </li>
                                        <li className="flex items-center">
                                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                                            Adresse
                                        </li>
                                        <li className="flex items-center">
                                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                                            Zahldaten
                                        </li>
                                        <li className="flex items-center">
                                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-3"></div>
                                            E-Mail-Adresse
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        {/* Data Processing Section */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <div className="flex items-center mb-6">
                                <LockClosedIcon className="h-8 w-8 text-blue-600 mr-3" />
                                <h2 className="text-2xl font-semibold text-gray-900">
                                    Datenverarbeitung & Speicherung
                                </h2>
                            </div>

                            <div className="prose prose-lg max-w-none text-gray-600">
                                <div className="space-y-6">
                                    <div className="bg-blue-50 rounded-lg p-6">
                                        <h3 className="text-xl font-medium text-blue-900 mb-3">
                                            Weitergabe an Dritte
                                        </h3>
                                        <p className="text-blue-800">
                                            Für die Auslieferung oder Auftragsabwicklung notwendige Daten werden an entsprechende Dienstleister weitergegeben. 
                                            Dies geschieht ausschließlich im erforderlichen Umfang und unter Beachtung aller Datenschutzbestimmungen.
                                        </p>
                                    </div>

                                    <div className="bg-green-50 rounded-lg p-6">
                                        <h3 className="text-xl font-medium text-green-900 mb-3">
                                            Löschung der Daten
                                        </h3>
                                        <p className="text-green-800">
                                            Ihre Daten werden gelöscht, sobald sie für die Geschäftszwecke nicht mehr erforderlich sind 
                                            und keine gesetzlichen Aufbewahrungspflichten mehr bestehen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Additional Information */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                                Ihre Rechte
                            </h2>
                            
                            <div className="grid md:grid-cols-2 gap-6">
                                <div className="bg-gray-50 rounded-lg p-6">
                                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                                        Auskunftsrecht
                                    </h3>
                                    <p className="text-gray-600">
                                        Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten.
                                    </p>
                                </div>

                                <div className="bg-gray-50 rounded-lg p-6">
                                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                                        Widerrufsrecht
                                    </h3>
                                    <p className="text-gray-600">
                                        Sie können der Verarbeitung Ihrer Daten jederzeit widersprechen oder eine erteilte Einwilligung widerrufen.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* Contact Section */}
                        <section className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-lg shadow-lg p-8 text-white">
                            <div className="text-center">
                                <h2 className="text-2xl font-semibold mb-4">
                                    Fragen zum Datenschutz?
                                </h2>
                                <p className="text-lg mb-6">
                                    Wenn Sie Fragen zum Datenschutz haben, kontaktieren Sie uns gerne unter:
                                </p>
                                <a href="mailto:info@md-teile.de" 
                                   className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    info@md-teile.de
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}