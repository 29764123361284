import {
    LineStyle,
    Timeline,
    TrendingUp,
    PersonOutline,
    Storefront,
    AttachMoney,
    MailOutline,
    DynamicFeed,
    ChatBubbleOutline,
    WorkOutline,
    Report,
    BarChart
} from '@material-ui/icons';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SidebarMenu({ loggedIn, setSidebar }) {
    const [activeItem, setActiveItem] = useState('');

    const handleMenuClick = (itemName) => {
        setActiveItem(itemName);
        setSidebar(false);
    }

    const menuItemClass = (itemName) => 
        `flex items-center space-x-3 px-4 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200 ${
            activeItem === itemName ? 'bg-blue-50 text-blue-600' : ''
        }`;

    return (
        <div className="h-full overflow-y-auto">
            {/* Main Menu */}
            <div className="py-6">
                <div className="px-4 flex justify-between items-center mb-6">
                    <h3 className="text-lg font-semibold text-gray-800">Menü</h3>
                    <button 
                        onClick={() => setSidebar(false)}
                        className="p-1 rounded-full hover:bg-gray-100 transition-colors duration-200"
                    >
                        <CloseIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
                    </button>
                </div>

                <nav>
                    <Link 
                        to="/shop" 
                        className={menuItemClass('shop')}
                        onClick={() => handleMenuClick('shop')}
                    >
                        <StorefrontIcon className="h-5 w-5" />
                        <span>Shop</span>
                    </Link>

                    <Link 
                        to="/contact" 
                        className={menuItemClass('contact')}
                        onClick={() => handleMenuClick('contact')}
                    >
                        <ContactMailIcon className="h-5 w-5" />
                        <span>Kontakt</span>
                    </Link>

                    <Link 
                        to="/interesting" 
                        className={menuItemClass('interesting')}
                        onClick={() => handleMenuClick('interesting')}
                    >
                        <AutoStoriesIcon className="h-5 w-5" />
                        <span>Wissenswertes</span>
                    </Link>
                </nav>
            </div>

            {/* Admin Menu */}
            {loggedIn && (
                <div className="py-6 border-t border-gray-200">
                    <div className="px-4 mb-4">
                        <h3 className="text-lg font-semibold text-gray-800">Admin Menu</h3>
                    </div>

                    <nav>
                        <Link 
                            to="/home" 
                            className={menuItemClass('home')}
                            onClick={() => handleMenuClick('home')}
                        >
                            <LineStyle className="h-5 w-5" />
                            <span>Home</span>
                        </Link>

                        <Link 
                            to="/users" 
                            className={menuItemClass('users')}
                            onClick={() => handleMenuClick('users')}
                        >
                            <PersonOutline className="h-5 w-5" />
                            <span>Users</span>
                        </Link>

                        <Link 
                            to="/products" 
                            className={menuItemClass('products')}
                            onClick={() => handleMenuClick('products')}
                        >
                            <Storefront className="h-5 w-5" />
                            <span>Products</span>
                        </Link>

                        <Link 
                            to="/invoice" 
                            className={menuItemClass('invoice')}
                            onClick={() => handleMenuClick('invoice')}
                        >
                            <DocumentScannerIcon className="h-5 w-5" />
                            <span>Rechnungen</span>
                        </Link>

                        <Link 
                            to="/orders" 
                            className={menuItemClass('orders')}
                            onClick={() => handleMenuClick('orders')}
                        >
                            <SellIcon className="h-5 w-5" />
                            <span>Orders</span>
                        </Link>
                    </nav>
                </div>
            )}
        </div>
    );
}
