import React from 'react';
import { BoltIcon, ArrowsUpDownIcon, CircleStackIcon, CubeIcon } from '@heroicons/react/24/outline';

export default function ProductDetails({ article }) {
    const details = [
        { label: 'Leistung', value: article.power, icon: BoltIcon },
        { label: 'Volt', value: article.volt, icon: CircleStackIcon },
        { label: 'Rillen', value: article.grooves, icon: ArrowsUpDownIcon },
        { label: 'Durchmesser', value: article.teeths, icon: CubeIcon }
    ].filter(detail => detail.value);

    return (
        <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                Technische Details
            </h3>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {details.map((detail, index) => (
                    <div key={index} className="bg-gray-50 rounded-lg p-4">
                        <div className="flex items-center">
                            <detail.icon className="h-5 w-5 text-blue-500 mr-2" />
                            <dt className="text-sm font-medium text-gray-500">
                                {detail.label}
                            </dt>
                        </div>
                        <dd className="mt-1 text-lg font-semibold text-gray-900">
                            {detail.value}
                        </dd>
                    </div>
                ))}
            </div>
        </div>
    );
}
