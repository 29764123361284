import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import UserCreation from './userCreation/userCreation'
import CustomerCreation from './customerCreation/customerCreation'
import { databaseLocation } from '../../dummyData'

const Register = ({ desiredPath }) => {
	const [error, setError] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [phone, setPhone] = useState('')
	const [company, setCompany] = useState('')
	const [street, setStreet] = useState('')
	const [postalcode, setPostalcode] = useState('')
	const [city, setCity] = useState('')
	const [country, setCountry] = useState('')
	const [addressExtra, setAddressExtra] = useState('')
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [mail, setMail] = useState('')
	const [phase, setPhase] = useState(0)

	let navigate = useNavigate()

	const verify1 = () => {
		if (mail.length < 3) {
			setError('Bitte gebe eine korrekte E-Mail an.')
			return false
		} else if (password.length < 3) {
			setError('Bitte gebe eine Passwort mit mindestens 3 Zeichen ein.')
			return false
		}
		setError('')
		setPhase(1)
		return true
	}

	const verify2 = () => {
		if (firstname.length < 3) {
			setError('Bitte gib einen Vornamen an.')
			return false
		} else if (lastname.length < 3) {
			setError('Bitte gib einen Nachnamen an.')
			return false
		}
		setError('')
		setPhase(2)
		return true
	}

	const registerHandler = () => {
		if (!verify2()) {
			return
		}

		axios
			.post(`${databaseLocation}/api/createCustomerAndUser`, {
				firstname: firstname,
				lastname: lastname,
				phone: phone,
				company: company,
				street: street,
				addressExtra: addressExtra,
				postalcode: postalcode,
				city: city,
				country: country,
				date: new Date(),
				email: mail,
				password: password,
				gender: 'unknown',
			})
			.then((res) => {
				if (res.data.msg.toLowerCase().includes('success')) {
					navigate(desiredPath)
				}
			})
	}

	return (
		<>
			{phase === 0 && (
				<UserCreation
					setUsername={setUsername}
					setPassword={setPassword}
					setMail={setMail}
					verify1={verify1}
					error={error}
				/>
			)}
			{phase === 1 && (
				<CustomerCreation
					setFirstname={setFirstname}
					setLastname={setLastname}
					setPhone={setPhone}
					setCompany={setCompany}
					setStreet={setStreet}
					setAddressExtra={setAddressExtra}
					setPostalcode={setPostalcode}
					setCity={setCity}
					setCountry={setCountry}
					registerHandler={registerHandler}
				/>
			)}
			{phase === 2 && (
				<div className="min-h-[calc(100vh-16rem)] flex items-center justify-center">
					<h1 className="text-3xl font-bold text-gray-900">Erfolgreich registriert!</h1>
				</div>
			)}
		</>
	)
}

export default Register
