import Charts from './chart/Chart'
import FeaturedInfo from './components/featuredInfo/FeaturedInfo'

export default function Home() {
  return (
    <div className="mt-16">
        <FeaturedInfo/>
        <Charts />
        {/* <div className="homeWidget">
          <WidgetSm />
          <WidgetLg />
        </div> */}
    </div>
  )
}
