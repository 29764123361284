import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="mt-12 py-8 bg-gray-50 border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row justify-around">
            <div className="flex flex-col items-center md:items-start my-6 md:my-0 md:mx-4 flex-1">
                <span className="mb-5 font-semibold text-xl text-gray-700 uppercase tracking-wider">Kontakt</span>
                <span className="mb-4 text-gray-600 text-sm leading-relaxed">Sie können uns von Mo - Fr von 8 - 17 Uhr erreichen.</span>
                <span className="mb-2 text-gray-700">Tel.: 030 308 373 62</span>
                <span className="text-gray-700">E-Mail: sales@md-teile.de</span>
            </div>
            <div className="flex flex-col items-center md:items-start my-6 md:my-0 md:mx-4 flex-1">
                <span className="mb-5 font-semibold text-xl text-gray-700 uppercase tracking-wider">Rechtliches</span>
                <Link to="/imprint" className="mb-2 text-gray-700 hover:text-blue-600 transition-colors duration-200">
                    Impressum
                </Link>
                <a href="./AGB.pdf" download className="mb-2 text-gray-700 hover:text-blue-600 transition-colors duration-200">
                    AGB
                </a>
                <Link to="/dataprotection" className="text-gray-700 hover:text-blue-600 transition-colors duration-200">
                    Datenschutzerklärung
                </Link>
            </div>
        </div>
    </footer>
  )
}
