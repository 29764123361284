import React from 'react'
import { TagIcon } from '@heroicons/react/24/outline';

export default function OENumbers({article}) {
    if (!article.oe) return null;

    const oeNumbers = article.oe.split(',').map(num => num.trim()).filter(Boolean);

    if (oeNumbers.length === 0) return null;

    return (
        <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center mb-4">
                <TagIcon className="h-6 w-6 text-blue-500 mr-2" />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    OE-Nummern
                </h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {oeNumbers.map((number, index) => (
                    <div 
                        key={index}
                        className="bg-gray-50 rounded-lg px-4 py-2 text-sm font-medium text-gray-900"
                    >
                        {number}
                    </div>
                ))}
            </div>
        </div>
    );
}
