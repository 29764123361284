import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import { DeleteOutline, Edit } from '@material-ui/icons'
import { userRows, databaseLocation } from '../../dummyData'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Axios from 'axios'
import './userList.css'
import NoPic from '../../nopic.png'
import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog'

export default function UserList() {
	const [data, setData] = useState()
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [userToDelete, setUserToDelete] = useState(null)

	useEffect(() => {
		let isMounted = true
		Axios.get(`${databaseLocation}/api/user/getAll`)
			.then((response) => {
				if (isMounted) {
					setData(response.data)

					response.data.map((user) => {
						if (user.image) {
							var arrayBufferView = new Uint8Array(user.image.data)
							var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
							var urlCreator = window.URL || window.webkitURL
							var imageUrl = urlCreator.createObjectURL(blob)
							user.avatar = imageUrl
						} else {
							user.avatar = String(NoPic)
						}
					})
				}
			})
			.catch((error) => {
				console.error('Unauthorized or other error occurred:', error)
			})

		return () => {
			isMounted = false
		}
	}, [])

	const handleDeleteClick = (userId) => {
		const user = data.find(u => u.userId === userId);
		setUserToDelete(user);
		setDeleteDialogOpen(true);
	};

	const handleDelete = async (userId) => {
		try {
			await Axios.post(`${databaseLocation}/api/deleteUser/${userId}`);
			setData(data.filter((user) => user.userId !== userId));
			console.log('Successfully deleted user with userId ' + userId);
		} catch (error) {
			console.error('Error deleting user:', error);
		}
	}

	const columns = [
		{ field: 'user', headerName: 'Username', width: 120 },
		{
			field: 'avatar',
			headerName: '',
			width: 60,
			renderCell: (params) => {
				return <img src={params.row.avatar} alt='Avatar' className='topAvatar' />
			},
		},
		{ field: 'level', headerName: 'Level', width: 90 },
		{ field: 'firstname', headerName: 'Firstname', width: 150 },
		{ field: 'lastname', headerName: 'Lastname', width: 150 },
		{
			field: 'gender',
			headerName: 'Gender',
			width: 80,
			renderCell: (params) => {
				return (
					(params.row.gender === 'female' && 'Female') ||
					(params.row.gender === 'male' && 'Male') ||
					'Unknown'
				)
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 90,
			renderCell: (params) => {
				return (
					(params.row.status === 1 && 'active') ||
					(params.row.status === 0 && 'deactivated')
				)
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 150,
			renderCell: (params) => {
				return (
					<>
						<div>
							<Link to={'/users/' + params.row.userId}>
								<Edit className='userListEdit'>
									Edit
								</Edit>
							</Link>
						</div>
						<div>
							<DeleteOutline
								className='userListDelete'
								onClick={() => handleDeleteClick(params.row.userId)}
							/>
						</div>
					</>
				)
			},
		},
	]

	return (
		<div className='userList'>
			<Link to='/newUser' className='addButtonLink'>
				<button className='userAddButton'>Create</button>
			</Link>
			{data && (
				<Box sx={{ height: 400, width: '100%' }}>
					<DataGrid
						rows={data}
						getRowId={(row) => row.userId}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[10]}
						disableSelectionOnClick
						sx={{ paddingLeft: '5px' }}
					/>
				</Box>
			)}

			<ConfirmationDialog
				isOpen={deleteDialogOpen}
				onClose={() => {
					setDeleteDialogOpen(false);
					setUserToDelete(null);
				}}
				onConfirm={() => {
					if (userToDelete) {
						handleDelete(userToDelete.userId);
					}
				}}
				title="Benutzer löschen"
				message={userToDelete ? `Möchten Sie den Benutzer "${userToDelete.user}" wirklich löschen?` : ''}
			/>
		</div>
	)
}
