import { signUp } from "../../../images";

const CustomerCreation = ({ setFirstname, setLastname, setPhone, setCompany, setStreet, setAddressExtra, setPostalcode, setCity, setCountry, registerHandler }) => {
    return (
        <div className="min-h-[calc(100vh-16rem)] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-2xl w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
                {/* Header */}
                <div>
                    <h2 className="text-center text-3xl font-bold text-gray-900">
                        Registrierung
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Diese Informationen brauchen wir um dich als Händler zu verifizieren.
                    </p>
                </div>

                {/* Form */}
                <div className="mt-8 space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-1">
                                Vorname*
                            </label>
                            <input
                                id="firstname"
                                type="text"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-1">
                                Nachname*
                            </label>
                            <input
                                id="lastname"
                                type="text"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
                                Firma*
                            </label>
                            <input
                                id="company"
                                type="text"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                                Telefon
                            </label>
                            <input
                                id="phone"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="street" className="block text-sm font-medium text-gray-700 mb-1">
                                Straße + Hausnummer
                            </label>
                            <input
                                id="street"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setStreet(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="addressExtra" className="block text-sm font-medium text-gray-700 mb-1">
                                Adresszusatz
                            </label>
                            <input
                                id="addressExtra"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setAddressExtra(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="postalcode" className="block text-sm font-medium text-gray-700 mb-1">
                                PLZ
                            </label>
                            <input
                                id="postalcode"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setPostalcode(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
                                Stadt
                            </label>
                            <input
                                id="city"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                                Land
                            </label>
                            <input
                                id="country"
                                type="text"
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                onChange={(e) => setCountry(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            onClick={registerHandler}
                            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                        >
                            Registrieren
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerCreation