import { Link } from 'react-router-dom'

const UserCreation = ({ setUsername, setPassword, setMail, verify1, error }) => {
    return (
        <div className="min-h-[calc(100vh-16rem)] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
                {/* Header */}
                <div className='flex flex-col justify-center items-center'>
                    <h2 className="text-center text-3xl font-bold text-gray-900">
                        Registrierung
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Dies werden deine Logindaten für unseren Webshop.
                    </p>
                    {error && (
                        <div className="mt-3 p-3 bg-red-50 rounded-lg">
                            <p className="text-sm text-red-600 text-center">
                                {error}
                            </p>
                        </div>
                    )}
                </div>

                {/* Form */}
                <div className="mt-8 space-y-6">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                id="email"
                                type="email"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                onChange={(e) => setMail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Passwort
                            </label>
                            <input
                                id="password"
                                type="password"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Passwort"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            onClick={verify1}
                            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                        >
                            Weiter
                        </button>
                    </div>

                    <div className="text-center">
                        <Link 
                            to="/login" 
                            className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                        >
                            Bereits ein Konto? Jetzt einloggen
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCreation