import { Helmet } from 'react-helmet';

export default function Interesting() {
    return (
        <>
            <Helmet>
                <title>Lichtmaschinen & Starter Expertenwissen | LaMaLagerWelt</title>
                <meta name="description" content="Erfahren Sie alles über Lichtmaschinen und Starter in Fahrzeugen. Expertenwissen zu Funktion, Wartung und Reparatur von Autoteilen. ✓ Professionelle Beratung ✓ Qualitätsprodukte" />
                <meta name="keywords" content="Lichtmaschine, Starter, Anlasser, Autoteile, Fahrzeugteile, Generator, Dynamo, Alternator, KFZ-Elektrik" />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Expertenwissen: Lichtmaschinen & Starter
                        </h1>
                        <p className="text-lg text-gray-600">
                            Ihr umfassender Leitfaden für essenzielle Fahrzeugkomponenten
                        </p>
                    </div>

                    <div className="space-y-12">
                        {/* Lichtmaschine Section */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-3xl font-semibold text-gray-900 mb-6">
                                Die Lichtmaschine: Das Kraftwerk Ihres Fahrzeugs
                            </h2>
                            
                            <div className="prose prose-lg max-w-none text-gray-600">
                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Funktion und Bedeutung
                                </h3>
                                <p className="mb-4">
                                    Die Lichtmaschine ist das zentrale Kraftwerk Ihres Fahrzeugs und erfüllt eine unverzichtbare Funktion: Sie wandelt mechanische Energie in elektrische Energie um und versorgt damit sämtliche elektrischen Systeme Ihres Fahrzeugs mit Strom.
                                </p>

                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Historische Entwicklung
                                </h3>
                                <p className="mb-4">
                                    Der Name "Lichtmaschine" stammt aus den Anfängen der Automobilgeschichte, als elektrische Energie hauptsächlich für die Beleuchtung benötigt wurde. Heute ist sie unter verschiedenen Bezeichnungen bekannt:
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                    <li>Generator</li>
                                    <li>Dynamo</li>
                                    <li>Alternator (englisch)</li>
                                </ul>

                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Technische Funktionsweise
                                </h3>
                                <p className="mb-4">
                                    Die moderne Lichtmaschine arbeitet nach einem ausgeklügelten Prinzip:
                                </p>
                                <ol className="list-decimal pl-6 mb-4">
                                    <li>Der Rotor (Wicklung) wird durch den Keilriemen und Motor angetrieben</li>
                                    <li>Diese Rotation erzeugt ein Magnetfeld</li>
                                    <li>Das Magnetfeld induziert elektrische Spannung</li>
                                    <li>Ein integrierter Regler stabilisiert die Spannung auf 12-14 Volt</li>
                                </ol>

                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Versorgung wichtiger Fahrzeugkomponenten
                                </h3>
                                <p className="mb-4">
                                    Die Lichtmaschine versorgt zahlreiche wichtige Komponenten mit Strom:
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                    <li>Fahrzeugbeleuchtung</li>
                                    <li>Klimaanlage</li>
                                    <li>Elektrische Fensterheber</li>
                                    <li>Audio- und Navigationssysteme</li>
                                    <li>Starter (Anlasser)</li>
                                    <li>Bordcomputer und Steuergeräte</li>
                                </ul>
                            </div>
                        </section>

                        {/* Starter Section */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-3xl font-semibold text-gray-900 mb-6">
                                Der Starter: Ihr zuverlässiger Motorstarter
                            </h2>

                            <div className="prose prose-lg max-w-none text-gray-600">
                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Funktion und Bedeutung
                                </h3>
                                <p className="mb-4">
                                    Der Starter (auch Anlasser genannt) ist eine unverzichtbare Komponente in Fahrzeugen mit Verbrennungsmotor. Seine Hauptaufgabe ist es, den Motor aus dem Stillstand in Bewegung zu setzen, damit dieser anschließend selbstständig weiterlaufen kann.
                                </p>

                                <div className="my-8">
                                    <img 
                                        src="./starter1.jpg" 
                                        alt="Hochwertiger Fahrzeugstarter" 
                                        className="rounded-lg shadow-md max-w-2xl mx-auto"
                                    />
                                    <p className="text-sm text-gray-500 text-center mt-2">
                                        Moderner Fahrzeugstarter mit optimierter Leistung
                                    </p>
                                </div>

                                <h3 className="text-2xl font-medium text-gray-900 mt-8 mb-4">
                                    Moderne Starter-Technologie
                                </h3>
                                <p className="mb-4">
                                    In unserem Shop bieten wir ausschließlich hochwertige, neue Starter an, die sich durch folgende Eigenschaften auszeichnen:
                                </p>
                                <ul className="list-disc pl-6 mb-4">
                                    <li>Hohe Zuverlässigkeit und Langlebigkeit</li>
                                    <li>Optimierte Leistungseffizienz</li>
                                    <li>Kompatibilität mit modernen Start-Stop-Systemen</li>
                                    <li>Geräuscharmer Betrieb</li>
                                </ul>

                                <div className="bg-blue-50 rounded-lg p-6 mt-8">
                                    <h4 className="text-xl font-medium text-blue-900 mb-2">
                                        Qualitätsversprechen
                                    </h4>
                                    <p className="text-blue-800">
                                        Wir von LaMaLagerWelt setzen auf höchste Qualität und bieten Ihnen ausschließlich Neuteile führender Hersteller an. Jeder Starter wird vor dem Versand sorgfältig geprüft, um Ihnen maximale Zuverlässigkeit zu garantieren.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* FAQ Section */}
                        <section className="bg-white rounded-lg shadow-lg p-8">
                            <h2 className="text-3xl font-semibold text-gray-900 mb-6">
                                Häufig gestellte Fragen
                            </h2>

                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-xl font-medium text-gray-900 mb-2">
                                        Wie lange hält eine Lichtmaschine?
                                    </h3>
                                    <p className="text-gray-600">
                                        Die Lebensdauer einer Lichtmaschine beträgt durchschnittlich 100.000 bis 150.000 Kilometer. Dies kann jedoch je nach Fahrzeugtyp und Nutzungsbedingungen variieren.
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-xl font-medium text-gray-900 mb-2">
                                        Woran erkenne ich einen defekten Starter?
                                    </h3>
                                    <p className="text-gray-600">
                                        Typische Anzeichen sind ein schleppender Motorstart, klackernde Geräusche beim Starten oder wenn der Motor trotz ausreichend geladener Batterie nicht anspringt.
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}