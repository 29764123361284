import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import NoPic from '../../nopic.png'

const Login = ({ loggedIn, setLoggedIn, setUser, desiredPath, databaseLocation, onLoginSuccess, isPopup = false }) => {
    const [loginStatus, setLoginStatus] = useState('')
    const [usernameTextfield, setUsernameTextfield] = useState('')
    const [passwordTextfield, setPasswordTextfield] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    let navigate = useNavigate()
    Axios.defaults.withCredentials = true

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress)
        isLoggedIn()
        return () => window.removeEventListener('keydown', handleKeyPress)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onLoggin = (user) => {
        if (user.image) {
            var arrayBufferView = new Uint8Array(user.image.data)
            var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
            var urlCreator = window.URL || window.webkitURL
            var imageUrl = urlCreator.createObjectURL(blob)
            user.avatar = imageUrl
        } else {
            user.avatar = String(NoPic)
        }

        if (!isPopup && desiredPath.length > 1 && desiredPath !== '/login') {
            navigate(desiredPath)
        } else if (!isPopup) {
            navigate('/home')
        }

        if (isPopup && onLoginSuccess) {
            onLoginSuccess()
        }

        setLoggedIn(true)
        setUser(user)
    }

    const isLoggedIn = async () => {
        await Axios.get(`${databaseLocation}/login`).then((response) => {
            setLoggedIn(response.data.loggedIn)

            if (response.data.loggedIn === true) {
                onLoggin(response.data.user)
            }
        })
    }

    const login = async () => {
        setIsLoading(true)
        await Axios.post(`${databaseLocation}/login`, {
            username: usernameTextfield,
            password: passwordTextfield,
        })
            .then((response) => {
                if (response.status === 200) {
                    onLoggin(response.data.user)
                } else {
                    setLoginStatus(response.data.msg)
                }
            })
            .catch((err) => {
                setLoginStatus(err.response.data.msg)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleKeyPress = (event) => {
        if (event.charCode === 13 || event.key === 'Enter') {
            if (loggedIn === false) {
                login()
            }
        }
    }

    const containerClass = isPopup 
        ? "space-y-8" 
        : "min-h-[calc(100vh-16rem)] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"

    const formContainerClass = isPopup
        ? ""
        : "max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg"

    return (
        <div className={containerClass}>
            <div className={formContainerClass}>
                {/* Header */}
                <div>
                    <h2 className="text-center text-3xl font-bold text-gray-900">
                        Login
                    </h2>
                    {loginStatus && (
                        <div className="mt-3 p-3 bg-red-50 rounded-lg">
                            <p className="text-sm text-red-600 text-center">
                                {loginStatus}
                            </p>
                        </div>
                    )}
                </div>

                {/* Form */}
                <div className="mt-8 space-y-6">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="username" className="sr-only">
                                Benutzername / Email
                            </label>
                            <input
                                id="username"
                                type="text"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Benutzername / Email"
                                onChange={(e) => setUsernameTextfield(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Passwort
                            </label>
                            <input
                                id="password"
                                type="password"
                                required
                                className="appearance-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                                placeholder="Passwort"
                                onChange={(e) => setPasswordTextfield(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            onClick={login}
                            disabled={isLoading}
                            className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white ${
                                isLoading 
                                    ? 'bg-blue-400 cursor-not-allowed' 
                                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                            } transition-colors duration-200`}
                        >
                            {isLoading ? (
                                <div className="flex items-center">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                                    Anmelden...
                                </div>
                            ) : (
                                'Login'
                            )}
                        </button>
                    </div>

                    {!isPopup && (
                        <div className="text-center">
                            <Link 
                                to="/register" 
                                className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                            >
                                Noch kein Konto? Jetzt registrieren
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Login
