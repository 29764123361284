import axios from "axios";
import React, { useEffect, useState } from 'react'
import { databaseLocation } from "./dummyData";
import LoginRegister from "./popups/loginRegister/loginRegister";
import { Link } from 'react-router-dom';
import NoPic from "./nopic.png";

const Cart = ({ loggedIn, user }) => {
    const [articleList, setArticleList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let isMounted = true;
        const fetchCart = async () => {
            try {
                const response = await axios.get(`${databaseLocation}/getCartArticles/${user.userId}`);
                if (isMounted) {
                    setArticleList(response.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching cart:', error);
                setIsLoading(false);
            }
        };

        if (loggedIn) {
            fetchCart();
        } else {
            setIsLoading(false);
        }
        
        return () => { 
            isMounted = false;
        }
    }, [loggedIn, user.userId]);

    const incrementHandler = async (article) => {
        try {
            await axios.put(`${databaseLocation}/cart/updateQuantity`, {
                customerId: user.userId,
                articleId: article.articleId,
                amount: (article.amount + 1)
            });
            updateArticleInList({ ...article, amount: article.amount + 1 });
        } catch (error) {
            console.error('Error incrementing quantity:', error);
        }
    };

    const decrementHandler = async (article) => {
        if (article.amount <= 0) return;
        if (article.amount === 1) return deleteHandler(article);
        
        try {
            await axios.put(`${databaseLocation}/cart/updateQuantity`, {
                customerId: user.userId,
                articleId: article.articleId,
                amount: (article.amount - 1)
            });
            updateArticleInList({ ...article, amount: article.amount - 1 });
        } catch (error) {
            console.error('Error decrementing quantity:', error);
        }
    };

    const deleteHandler = async (article) => {
        try {
            await axios.delete(`${databaseLocation}/cart/remove/${user.userId}/${article.articleId}`);
            setArticleList(articleList.filter(entity => article.articleId !== entity.articleId));
        } catch (error) {
            console.error('Error removing item:', error);
        }
    };

    const updateArticleInList = (updatedArticle) => {
        setArticleList(prevList => 
            prevList.map(item => 
                item.articleId === updatedArticle.articleId ? updatedArticle : item
            )
        );
    };

    const calculateTotal = () => {
        return articleList.reduce((total, article) => total + (article.ebayPlus * article.amount), 0).toFixed(2);
    };

    return (
        <div className="min-h-[calc(100vh-16rem)] container mx-auto px-4 py-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Warenkorb</h1>

                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                    </div>
                ) : articleList.length === 0 ? (
                    <div className="text-center py-12">
                        <p className="text-xl text-gray-600 mb-8">Du hast noch nichts in deinem Warenkorb.</p>
                        <Link 
                            to="/shop" 
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
                        >
                            Zum Shop
                        </Link>
                    </div>
                ) : (
                    <div className="space-y-8">
                        {articleList.map((article, index) => (
                            <div 
                                key={index}
                                className="bg-white rounded-lg shadow-md overflow-hidden"
                            >
                                <div className="p-6">
                                    <div className="flex items-center space-x-6">
                                        {/* Product Image */}
                                        <div className="flex-shrink-0 w-32 h-32">
                                            <Link to={`/shop/${article.articleId}`}>
                                                <img
                                                    src={article.URL && article.URL.length > 3 ? article.URL : NoPic}
                                                    alt={article.articleType}
                                                    className="w-full h-full object-cover rounded-md"
                                                />
                                            </Link>
                                        </div>

                                        {/* Product Details */}
                                        <div className="flex-1">
                                            <Link 
                                                to={`/shop/${article.articleId}`}
                                                className="text-lg font-medium text-gray-900 hover:text-blue-600 transition-colors duration-200"
                                            >
                                                {`${article.brand} ${article.articleType.replace("Alternator", "Lichtmaschine")} ${article.power.replace(" ", "")} ${article.articleNumber} ${article.manufacturer}`}
                                            </Link>
                                            
                                            <div className="mt-2 space-y-2">
                                                {article.volt && (
                                                    <p className="text-sm text-gray-600">
                                                        Volt: {article.volt}
                                                    </p>
                                                )}
                                                {article.grooves && (
                                                    <p className="text-sm text-gray-600">
                                                        {article.articleType === "Lichtmaschine" ? "Grooves:" : "Teeth:"} {article.grooves}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="mt-4 flex items-center justify-between">
                                                <div className="flex items-center space-x-4">
                                                    <button
                                                        onClick={() => decrementHandler(article)}
                                                        className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300 hover:bg-gray-100 transition-colors duration-200"
                                                    >
                                                        -
                                                    </button>
                                                    <span className="text-gray-900 font-medium">
                                                        {article.amount}
                                                    </span>
                                                    <button
                                                        onClick={() => incrementHandler(article)}
                                                        className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300 hover:bg-gray-100 transition-colors duration-200"
                                                    >
                                                        +
                                                    </button>
                                                    <button
                                                        onClick={() => deleteHandler(article)}
                                                        className="ml-4 text-sm text-red-600 hover:text-red-800 transition-colors duration-200"
                                                    >
                                                        Entfernen
                                                    </button>
                                                </div>
                                                <div className="text-lg font-medium text-gray-900">
                                                    {(article.ebayPlus * article.amount).toFixed(2)} €
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Cart Summary */}
                        <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-lg font-medium text-gray-900">Gesamtsumme</span>
                                <span className="text-2xl font-bold text-gray-900">{calculateTotal()} €</span>
                            </div>
                            <button
                                className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200"
                            >
                                Zur Kasse
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;