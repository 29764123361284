import { Helmet } from 'react-helmet';
import { databaseLocation } from '../../dummyData';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import ShopProductsListing from '../../components/shopProductsList/shopProductsListing';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';

export default function Shop() {
    const [vehicleList, setVehicleList] = useState([]);
    const [articleList, setArticleList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                setIsLoading(true);
                const response = await Axios.get(`${databaseLocation}/api/articles/getAllWithQuantity`);
                setArticleList(response.data);
                setFilteredList(response.data);
                setError(null);
            } catch (err) {
                setError('Fehler beim Laden der Artikel. Bitte versuchen Sie es später erneut.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchArticles();
    }, []);

    const searchHandler = (e) => {
        const value = e.target.value;
        setSearchText(value);
        
        if (value.length > 0) {
            const searchTxt = value.toLowerCase().replaceAll(' ', '');
            setFilteredList(
                articleList.filter(
                    (article) =>
                        article.articleNumber.toLowerCase().includes(searchTxt) ||
                        article.oe?.toLowerCase().includes(searchTxt)
                )
            );
        } else {
            setFilteredList(articleList);
        }
    };

    const clearSearch = () => {
        setSearchText('');
        setFilteredList(articleList);
    };

    return (
        <>
            <Helmet>
                <title>Shop | Maschinenbau Dünder - Qualitäts-Autoteile Online</title>
                <meta 
                    name="description" 
                    content="Entdecken Sie unser breites Sortiment an hochwertigen Autoteilen. Einfache Suche nach Teilenummer und schnelle Lieferung." 
                />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    {/* Search Section */}
                    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                        <div className="max-w-3xl mx-auto">
                            <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
                                Finden Sie das richtige Teil
                            </h1>
                            <div className="relative">
                                <div className="flex items-center border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 ml-3" />
                                    <input
                                        type="text"
                                        placeholder="Suchen Sie nach Teilenummer..."
                                        value={searchText}
                                        onChange={searchHandler}
                                        className="w-full py-3 px-4 text-gray-700 focus:outline-none"
                                    />
                                    {searchText && (
                                        <button
                                            onClick={clearSearch}
                                            className="p-2 hover:text-gray-600 focus:outline-none"
                                        >
                                            <XCircleIcon className="h-5 w-5 text-gray-400" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="space-y-6">
                        {isLoading ? (
                            <div className="text-center py-12">
                                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                                <p className="mt-4 text-gray-600">Laden...</p>
                            </div>
                        ) : error ? (
                            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
                                <div className="flex">
                                    <div className="ml-3">
                                        <p className="text-red-700">{error}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {filteredList.length > 0 ? (
                                    <div className="grid gap-6">
                                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                                            {filteredList.slice(0, 101).map((article) => (
                                                <div 
                                                    key={article.articleId}
                                                    className="transform transition duration-200 hover:-translate-y-1"
                                                >
                                                    <ShopProductsListing article={article} />
                                                </div>
                                            ))}
                                        </div>
                                        
                                        <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 text-center">
                                            <h3 className="text-2xl font-semibold text-blue-900 mb-3">
                                                Nicht das richtige gefunden?
                                            </h3>
                                            <p className="text-blue-700">
                                                Kontaktieren Sie uns gerne, wir finden das richtige für Sie!
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center py-12">
                                        <MagnifyingGlassIcon className="mx-auto h-12 w-12 text-gray-400" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">Keine Ergebnisse</h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Versuchen Sie es mit anderen Suchbegriffen oder kontaktieren Sie uns für Hilfe.
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
