import { Link } from 'react-router-dom';
import NoPic from "../../nopic.png";
import { TagIcon, BoltIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';

export default function ShopProductsListing({ article }) {
    const isInStock = article.quantity > 0;
    
    return (
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
            <Link 
                to={"/shop/"+article.articleId} 
                className="block h-full"
            >
                <div className="relative aspect-square">
                    <img 
                        src={article.URL && article.URL.length > 3 ? article.URL : NoPic} 
                        alt={article.articleType || 'Produktbild'} 
                        className="w-full h-full object-cover"
                    />
                    {/* Price Badge */}
                    <div className="absolute top-4 right-4 bg-white px-3 py-1.5 rounded-full shadow-md flex items-center space-x-1">
                        <CurrencyEuroIcon className="h-4 w-4 text-gray-600" />
                        <span className="font-semibold text-gray-900">{article.ebayPlus}</span>
                    </div>
                </div>

                <div className="p-4">
                    {/* Title */}
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 line-clamp-2">
                        {article.brand} {article.articleType.replace("Alternator", "Lichtmaschine")} {article.power.replace(" ", "")} {article.articleNumber} {article.manufacturer}
                    </h3>

                    {/* Details */}
                    <div className="space-y-2 mb-4">
                        {article.volt && (
                            <div className="flex items-center text-gray-600">
                                <BoltIcon className="h-5 w-5 mr-2" />
                                <span>{article.volt}V</span>
                            </div>
                        )}
                        {article.grooves && (
                            <div className="flex items-center text-gray-600">
                                <TagIcon className="h-5 w-5 mr-2" />
                                <span>
                                    {article.articleType === "Lichtmaschine" ? "Rillen: " : "Zähne: "}
                                    {article.grooves}
                                </span>
                            </div>
                        )}
                    </div>

                    {/* Availability Badge */}
                    <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                        ${isInStock 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'}`}
                    >
                        {isInStock ? 'Verfügbar' : 'Ausverkauft'}
                    </div>
                </div>
            </Link>
        </div>
    );
}
